<template>
  <svg
    fill="none"
    height="20"
    viewBox="0 0 19 20"
    width="19"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.93282 5.10077L5.06718 7.89923C5.02338 7.96494 5 8.04214 5 8.12111V15.6877C5 15.8712 5.12492 16.0312 5.30299 16.0757L6.96529 16.4913C6.98838 16.4971 7.01116 16.5008 7.03491 16.5023C9.54382 16.6651 14.241 16.5 15.5 16.5C16.9026 16.5 17.4308 15.1886 17.0848 14.6094C17.0498 14.5508 17.0633 14.4682 17.121 14.4317C17.957 13.9022 17.9788 12.6109 17.5657 12.075C17.5319 12.0313 17.5321 11.9682 17.5695 11.9276C18.4602 10.9622 18.1671 9.71715 17.5499 9.05184C17.5207 9.02029 17.5135 8.97341 17.5317 8.9344C18.2645 7.36193 16.9863 6 16 6H10.9C10.6791 6 10.5 5.82112 10.5 5.6002V2.5C10.5 0.708509 8.62783 0.120558 7.39897 0.0174366C7.17883 -0.00103672 7 0.179086 7 0.4V4.87889C7 4.95786 6.97662 5.03506 6.93282 5.10077Z"
      fill="currentColor"
    />
    <rect
      fill="currentColor"
      height="10"
      rx="0.5"
      width="4"
      y="7"
    />
  </svg>
</template>
