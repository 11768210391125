<template>
  <svg
    class="v-favorite-heart"
    fill="none"
    height="16"
    viewBox="0 0 18 16"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 5.72651e-08C12.2238 5.72651e-08 11.4582 0.180725 10.7639 0.527861C10.0697 0.874998 9.46574 1.37901 9 1.99999C8.37035 1.16046 7.49252 0.54033 6.49084 0.227431C5.48916 -0.085468 4.41442 -0.0752693 3.41886 0.256582C2.4233 0.588434 1.55739 1.22511 0.943789 2.07644C0.330187 2.92776 0 3.95056 0 4.99997C0 10.9916 8.325 15.7166 8.675 15.9166C8.77417 15.9723 8.88624 16.001 9 15.9999C9.11386 16.0019 9.22613 15.9731 9.325 15.9166C10.8581 15.0203 12.296 13.9705 13.6167 12.7833C16.525 10.1666 18 7.54996 18 4.99997C18 3.6739 17.4732 2.40213 16.5355 1.46446C15.5979 0.526781 14.3261 5.72651e-08 13 5.72651e-08Z"
      fill="currentColor"
    />
  </svg>
</template>

<style lang="scss">
.v-favorite-heart {
  fill: none;
  width: 18px;
  height: 16px;
}
</style>
