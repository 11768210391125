<template>
  <svg
    class="v-broken-heart"
    fill="none"
    height="16"
    viewBox="0 0 18 16"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clip-rule="evenodd"
      d="M10.7639 0.527861C11.4582 0.180725 12.2238 0 13 0C14.3261 0 15.5979 0.526781 16.5355 1.46446C17.4732 2.40213 18 3.6739 18 4.99997C18 7.54996 16.525 10.1666 13.6167 12.7833C12.296 13.9705 10.8581 15.0203 9.325 15.9166C9.22613 15.9731 9.11386 16.0019 9 15.9999C8.88624 16.001 8.77417 15.9723 8.675 15.9166L8.66942 15.9134C8.24544 15.6713 0 10.9629 0 4.99997C0 3.95056 0.330187 2.92776 0.943789 2.07644C1.55739 1.22511 2.4233 0.588434 3.41886 0.256582C4.41442 -0.0752691 5.48916 -0.0854678 6.49084 0.227431C6.82282 0.331135 7.14121 0.468588 7.44146 0.636592L6.06192 3.85553C6.02558 3.94032 6.05195 4.03896 6.12575 4.09431L9.89677 6.92258C9.95962 6.96971 9.98905 7.04928 9.97201 7.12596L9.26736 10.2969C9.21814 10.5184 9.51736 10.6377 9.63409 10.4432L11.9197 6.6339C11.9669 6.55521 11.9545 6.45448 11.8896 6.38958L9.10802 3.60802C9.04424 3.54424 9.03104 3.44568 9.07579 3.36737L10.6712 0.57546C10.7019 0.559281 10.7328 0.543414 10.7639 0.527861Z"
      fill="currentColor"
      fill-rule="evenodd"
    />
  </svg>
</template>

<style lang="scss">
.v-broken-heart {
  fill: none;
  width: 18px;
  height: 16px;
}
</style>
