<template>
  <svg
    fill="none"
    height="19"
    viewBox="0 0 14 19"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.85 18.9982C12.691 18.9987 12.5374 18.9384 12.4182 18.8286L7 13.7996L1.58185 18.8286C1.48808 18.9156 1.3722 18.9724 1.24819 18.9922C1.12418 19.012 0.997334 18.9939 0.882958 18.9401C0.768582 18.8863 0.671564 18.7991 0.603612 18.689C0.535659 18.5789 0.499676 18.4507 0.500002 18.3197V2.71403C0.500755 1.99447 0.774924 1.3046 1.26236 0.79579C1.74979 0.28698 2.41067 0.000785736 3.1 0H10.9C11.5893 0.000785736 12.2502 0.28698 12.7376 0.79579C13.2251 1.3046 13.4992 1.99447 13.5 2.71403V18.3197C13.5 18.4997 13.4315 18.6723 13.3096 18.7995C13.1877 18.9268 13.0224 18.9982 12.85 18.9982Z"
      fill="currentColor"
    />
  </svg>
</template>
