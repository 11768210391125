<template>
  <icon-general-bookmark v-if="appConfig.VueSettingsPreRun.MenuFavoritesIcon === 'Bookmark'" />
  <template v-else-if="appConfig.VueSettingsPreRun.MenuFavoritesIcon === 'Heart'">
    <icon-general-broken-heart v-if="broken" />
    <icon-general-heart v-else />
  </template>
  <icon-general-star v-else-if="appConfig.VueSettingsPreRun.MenuFavoritesIcon === 'Star'" />
  <icon-general-thumb-Up v-else-if="appConfig.VueSettingsPreRun.MenuFavoritesIcon === 'Thumbs'" />
</template>

<script setup lang="ts">
const { broken = false } = defineProps<{
  broken?: boolean
}>()

const appConfig = useAppConfig()
</script>
